import React from 'react'
import { useSelector } from 'react-redux';
import { StateGeneralRedux } from '../../types/redux';
import { Col, Row, Typography } from 'antd';
import { ToastContainer } from 'react-toastify';
import iconIRGT from '../../image/auth/inrgt-logo@3x.png'
import './RegisterPage.css'
import RegisterCard from '../../components/auth/register/registerCard/RegisterCard';
import { useNavigate } from 'react-router-dom';
const RegisterPage = () => {

    // States
    const { dictionary } = useSelector((state: StateGeneralRedux) => state.ui);
    const navigate = useNavigate();
    return (
        <div className='container-register-page'>
            <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick pauseOnFocusLoss draggable pauseOnHover />
            <Row className='row-section-register'>
                <Col className='col-register-page-one' xs={24} sm={24} md={24} lg={24} xl={11} xxl={14}>
                    <Row className="row-icon-irgt" >
                        <button className='button-logo-career-swipe'>
                            <img
                                src={iconIRGT}
                                className="icon-irgt-logo"
                                alt="INRGT logo"
                                loading="lazy"
                                decoding="async"
                                onClick={() => navigate('/login')}
                            />

                        </button>


                    </Row>

                    <Row style={{ padding: '0px 50px' }}>
                        <Typography.Title style={{ fontSize: '60px', fontWeight: 'bold', color: 'white' }}>
                            {dictionary['register-page-welcome-message']}
                        </Typography.Title>
                    </Row>

                    <Row className='row-text-secondary'>
                        <Typography.Text style={{ fontSize: '20px', fontWeight: 'bold', color: 'white' }}>
                            {dictionary['register-page-secondary-message']}

                        </Typography.Text>
                    </Row>
                </Col>

                <Col xs={24} sm={{ span: 24 }} md={24} lg={24} xl={13} xxl={10} style={{ height: '100%', width: '100%', overflowY: 'auto', backgroundColor: 'white', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', boxSizing: 'border-box' }}>
                    <RegisterCard />
                </Col>
            </Row>
        </div>
    )
}

export default RegisterPage;