import React, { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { Form, Input, Select, Button, Row, Col, InputNumber } from 'antd';
import './index.css';
import { verificationTokenGoogle } from '../../../../../hooks/hooks';
import { registerWithEmailAndPassword, setHiringRequest } from '../../../../../firebase/services/hiring';
import { StateGeneralRedux } from '../../../../../types/redux';
import { useSelector } from 'react-redux';
import { API_RECAPTCHA_SITE_KEY } from '../../../../../api/secrets';
import { Timestamp } from 'firebase/firestore';

const countryCodes = [
    { code: '+1', country: 'Canada' },
    { code: '+56', country: 'Chile' }
];
const forbiddenDomains = ['hotmail.com', 'yahoo.com', 'outlook.com', 'aol.com', 'yandex.com', 'mail.com', 'tempmail.com', 'guerrillamail.com', '10minutemail.com'];

// Interface 
interface Props {
    setStep: (value: 'Initial' | 'VerificationEmail' | 'completeRegistration' | 'Error' | 'Loading') => void;
    setUserInformation: (value: any) => void;
}
// Components
const RegisterForm: React.FC<Props> = ({ setStep, setUserInformation }) => {

    // States
    const reCaptchaRef = useRef<any>();
    const [form] = Form.useForm();
    const [countryCode, setCountryCode] = useState('+1');
    const [phoneNumber, setPhoneNumber] = useState<number | null>(null);
    const [loading, setLoading] = useState(false);
    const { dictionary } = useSelector((state: StateGeneralRedux) => state.ui);
    // Recatpcha
    const [captchaDone, setCaptchaDone] = useState<boolean>(false);
    const [token, setToken] = useState<string | null>(null);

    const registerUser = async (value: any) => {
        // Iniciar estado cargando
        setLoading(true);
        const verificationTokenGoogleResponse = await verificationTokenGoogle(token);
        if (!verificationTokenGoogleResponse) {
            return reCaptchaRef.current.reset();
        }
        // Validar campos
        let phoneNumberFormatted = '';

        if (!phoneNumber) {
            console.log('PhoneNumber is empty string');
        } else {
            console.log(`PhoneNumber: ${countryCode} ${phoneNumber}`);
            phoneNumberFormatted = `${countryCode}${phoneNumber}`;
        }

        // Llamar a function de firebase createUserWithEmailAndPassword para crear un nuevo usuario en firebase
        const res = await registerWithEmailAndPassword(value.email, value.password);

        // Enviar email para verificiar email
        if (res.success) {
            setUserInformation({
                name: value.name,
                lastName: value.lastName,
                email: value.email,
                phoneNumber: phoneNumberFormatted,
                companyName: value.company,
                idCompany: null,
                idHiring: res.user?.uid,
                createDate: Timestamp.now(),
            });
            if (!res.user?.uid) { return; }

            await setHiringRequest(value.name, value.lastName, value.email, phoneNumberFormatted, value.company, '', res.user?.uid);
            // Cambiar estado a verification
            setStep('VerificationEmail');
        } else if (!res.success) {
            setLoading(false);
            setCaptchaDone(false);
            reCaptchaRef.current.reset();
            if (res.error.code === 'auth/email-already-in-use') {
                form.setFields([
                    {
                        name: 'email',
                        errors: [dictionary['register-form-email-already-in-use']]
                    }
                ]);
            }
        }
    }

    // Función para validar el dominio del correo electrónico
    const validateEmailDomain = (_: any, value: any) => {
        if (!value) {
            return Promise.resolve();
        }
        const domain = value.split('@')[1];
        if (forbiddenDomains.includes(domain)) {
            return Promise.reject(new Error(dictionary['register-form-invalid-email-domain']));
        }
        return Promise.resolve();
    };

    const onChange = (value: string | null) => {
        setCaptchaDone(true);
        setToken(value);
    };

    useEffect(() => {
        // Limpiar el formulario cuando el componente se desmonte
        return () => {
            form.resetFields();
        };
    }, [form]);

    return (
        <Form form={form} className='registerForm' onFinish={registerUser} layout='vertical'>
            <Row style={{ width: '100%' }}>
                <Col xs={{ span: 11, offset: 1 }} xl={{ span: 8, offset: 3 }} xxl={{ span: 8, offset: 3 }}>
                    <Form.Item
                        label={dictionary['register-form-name-label']}
                        name="name"
                        rules={[
                            { required: true, message: dictionary['register-form-name-required'] },
                            { min: 2, message: dictionary['register-form-name-min-length'] },
                            { max: 50, message: dictionary['register-form-name-max-length'] },
                            { pattern: /^[A-Za-zÀ-ÖØ-öø-ÿ' -]+$/, message: dictionary['register-form-name-pattern'] },
                            { whitespace: true, message: dictionary['register-form-name-whitespace'] }
                        ]}
                        initialValue=""
                        className="custom-form-item"
                    >
                        <Input className='custom-input' placeholder={dictionary['register-form-name-placeholder']} size="middle" />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 10, offset: 1 }} xl={{ span: 8, offset: 1 }} xxl={{ span: 8, offset: 1 }}>
                    <Form.Item
                        label={dictionary['register-form-last-name-label']}
                        name="lastName"
                        rules={[
                            { required: true, message: dictionary['register-form-last-name-required'] },
                            { min: 2, message: dictionary['register-form-last_name-min-length'] },
                            { max: 50, message: dictionary['register-form.last-name-max-length'] },
                            { pattern: /^[A-Za-zÀ-ÖØ-öø-ÿ' -]+$/, message: dictionary['register-form-last-name-pattern'] },
                            { whitespace: true, message: dictionary['register-form-last-name-whitespace'] }
                        ]}
                        initialValue=""
                        className="custom-form-item"
                    >
                        <Input className='custom-input' placeholder={dictionary['register-form-last-name-placeholder']} size="middle" />
                    </Form.Item>
                </Col>

                <Col xs={{ span: 22, offset: 1 }} xl={{ span: 17, offset: 3 }} xxl={{ span: 17, offset: 3 }}>
                    <Form.Item
                        className="custom-form-item"
                        name="company"
                        label={dictionary['register-form-company-label']}
                        rules={[{ required: true, message: dictionary['register-form-company_required'] }]}
                    >
                        <Input className='custom-input' placeholder={dictionary['register-form-company-placeholder']} size="middle" />
                    </Form.Item>
                </Col>

                <Col xs={{ span: 22, offset: 1 }} xl={{ span: 17, offset: 3 }} xxl={{ span: 17, offset: 3 }}>
                    <Form.Item
                        label={dictionary['register-form-email-label']}
                        name="email"
                        className="custom-form-item"
                        rules={[
                            { required: true, message: dictionary['register-form-email-required'] },
                            { type: 'email', message: dictionary['register_-orm-email-invalid'] },
                            { max: 254, message: dictionary['register-form-email-max_-ength'] },
                            { validator: validateEmailDomain }
                        ]}
                    >
                        <Input className='custom-input' placeholder={dictionary['register-form-email-placeholder']} size="middle" />
                    </Form.Item>
                </Col>

                <Col xs={{ span: 22, offset: 1 }} xl={{ span: 17, offset: 3 }} xxl={{ span: 17, offset: 3 }}>
                    <Form.Item
                        label={dictionary['register-form-phone-label']}
                        name="phoneNumber"
                        className="custom-form-item"
                    >
                        <Input.Group compact style={{ display: 'flex', alignItems: 'stretch' }}>
                            <Select
                                defaultValue="+1"
                                size="large"
                                style={{ width: '30%', flexShrink: 0 }}
                                onChange={setCountryCode}
                            >
                                {countryCodes.map(({ code, country }) => (
                                    <Select.Option key={code} value={code}>{code} ({country})</Select.Option>
                                ))}
                            </Select>
                            <InputNumber
                                placeholder={dictionary['register-form-phone-placeholder']}
                                size="small"
                                style={{ width: '70%', backgroundColor: '#00000', padding: '7px' }}
                                maxLength={10}
                                min={10000000}
                                max={9999999999}
                                onChange={value => setPhoneNumber(value)}
                            />
                        </Input.Group>
                    </Form.Item>
                </Col>

                <Col xs={{ span: 22, offset: 1 }} xl={{ span: 17, offset: 3 }} xxl={{ span: 17, offset: 3 }}>
                    <Form.Item
                        label={dictionary['register-form-password-label']}
                        name="password"
                        className="custom-form-item"
                        rules={[
                            { required: true, message: dictionary['register-form-password_-equired'] },
                            { min: 8, message: dictionary['register-form-password-min_-ength'] },
                            {
                                pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).+$/,
                                message: dictionary['register-form-password-pattern']
                            }
                        ]}
                        initialValue=""
                    >
                        <Input.Password className='custom-input' placeholder={dictionary['register-form-password-placeholder']} size="middle" visibilityToggle />
                    </Form.Item>
                </Col>

                <Col xs={{ span: 22, offset: 1 }} xl={{ span: 17, offset: 3 }} xxl={{ span: 17, offset: 3 }}>
                    <Form.Item
                        className="custom-form-item"
                        label={dictionary['register-form-confirm-password-label']}
                        name="confirmPassword"
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                            { required: true, message: dictionary['register-form-confirm-password-required'] },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error(dictionary['register-form-confirm-password-mismatch']));
                                },
                            }),
                        ]}
                        initialValue=""
                    >
                        <Input.Password className='custom-input' placeholder={dictionary['register-form-confirm-password-placeholder']} size="middle" visibilityToggle />
                    </Form.Item>
                </Col>

                <Col xs={{ span: 22, offset: 1 }} xl={{ span: 17, offset: 3 }} xxl={{ span: 17, offset: 3 }}>
                    <ReCAPTCHA
                        ref={reCaptchaRef}
                        sitekey={API_RECAPTCHA_SITE_KEY}
                        onChange={onChange}
                        style={{
                            marginBottom: '20px',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            width: '100%',
                            maxWidth: '320px',
                            margin: '0 auto 20px',
                            borderRadius: "20px"
                        }}
                    />
                </Col>

                <Col xs={{ span: 22, offset: 1 }} xl={{ span: 17, offset: 3 }} xxl={{ span: 17, offset: 3 }}>
                    <Form.Item>
                        <Button
                            loading={loading}
                            size='large'
                            style={{
                                background: 'linear-gradient(16deg, rgb(96, 82, 163), rgb(50, 45, 90))',
                                color: 'white',
                                width: '100%',
                                borderColor: '#357abD',
                                borderRadius: '25px',
                                boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)',
                                transition: 'all 0.3s ease',
                                fontSize: '16px',
                                fontWeight: 'bold'
                            }}
                            htmlType="submit"
                            disabled={!captchaDone}
                            onMouseEnter={e => e.currentTarget.style.backgroundColor = '#357abD'}
                            onMouseLeave={e => e.currentTarget.style.backgroundColor = '#4a90e2'}
                            onFocus={e => e.currentTarget.style.backgroundColor = '#357abD'}
                            onBlur={e => e.currentTarget.style.backgroundColor = '#4a90e2'}
                        >
                            {dictionary['register-form-create-account-button']}
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form >
    );
}

export default RegisterForm;
