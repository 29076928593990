import React from "react";
import { useSelector } from "react-redux";
import { Row, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";

import LoginForm from "../../components/auth/LoginForm";
import { StateGeneralRedux } from "../../types/redux";
import { getTranslation } from "../../hooks/hooks";

import './index.css';

const { Text } = Typography;

const LoginPage: React.FC = () => {
  const { dictionary } = useSelector((state: StateGeneralRedux) => state.ui);

  return (
    <Content className="container-login-page">
      <div className="body-login-page">
        <LoginForm />
      </div>

      <Row style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: "auto" }}>
        <Text
          style={{
            fontSize: 18,
            fontFamily: 'Muli',
            marginRight: 4
          }}
        >
          {getTranslation("login-initial-page-info-page-text", dictionary)}
        </Text>

        <Text
          style={{
            fontSize: 18,
            fontFamily: 'Muli',
            fontWeight: 'bold',
            textDecoration: 'underline dotted blue',
            boxSizing: 'border-box',
            padding: '0px 20px'
          }}
        >
          gocareerswipe.ca
        </Text>
      </Row>

      <Row style={{
        width: '100%', boxSizing: 'border-box',
        padding: '0px 20px', display: 'flex', justifyContent: 'center', alignItems: 'center', height: "auto"
      }}>
        <Text style={{ fontSize: 18, fontFamily: 'Muli', textAlign: 'center' }}>
          {getTranslation("change-password-informative-email-text", dictionary)}
        </Text>
      </Row>

      <Row className="bar-footer-login-page" />
    </Content>
  );
};

export default LoginPage;
