import axios from "axios"
import { EEmail, EEmailLanguage } from "../models/emails";
import routes from "../api";
const baseURL = routes.mode === "dev" ? routes.dev_route : routes.qa_route
export const fetchSendNotificationLike = async (id: string) => {
    try {

    } catch (error) {
        return {

        }
    }
}

export const fetchSendEmail = async (email: string, typeEmail: EEmail, language: EEmailLanguage, context: any, token: string) => {
    try {

        const configSendEmail = {
            headers: {
                Authorization: token,
                'Content-Type': 'application/json', // Asegura que el contenido se envíe como JSON
            },
        };

        const body = {
            email: email.trim(),
            typeEmail,
            language: language.trim(),
            context
        };

        await axios.post(`${baseURL}/email/sendEmailNotification`, body, configSendEmail);
    } catch (error) {
        return {

        }
    }
}
